import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";

const Thumbnail = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
  //changed the below element from <a> to a Button in hopes of making modal work
  <>
  <Button
    variant=""
    css={{
      display: "block",
      flex: "1 0 0%",
      marginRight: "0.175rem",
      width: "100%",
      maxWidth: 290.1,
      position: "relative",
      padding: 0,
      "@media(min-width: 750px)": {
        marginRight: "1.4rem",
      },
      ":last-child": {
        marginRight: 0,
      },
    }}
    onClick={handleShow}
  >
    <Img
      css={{
        margin: 0,
        height: "100%",
        width: "100%",
        verticalAlign: "baseline",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      key={props.key}
      fluid={props.fluid}
      alt={props.alt}
    />
  </Button>
  <Modal centered show={show} onHide={handleClose}>
    <Img fluid={props.fluid} />
  </Modal>
  </>
)};

const chunkArray = (arr, num) => {
  let newArr = [];
  while (arr.length) {
    newArr.push(arr.splice(0, num));
  }
  return newArr;
};

const Gallery = ({ data, show, onClick, onHide }) => {
  const galleryData = data.allFile.edges;
 
  return (
    <Layout>
      <header
        css={{
          margin: "5rem 0 1.25rem",
          position: "relative",
          background: "no-repeat center center",
          backgroundSize: "cover",
          //marginTop: '3.1875rem',
          //marginBottom: '2.1875rem',
          "@media(min-width: 992px)": {
            marginTop: "8.125rem",
          },
        }}
      >
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
              <h1
                css={(theme) => ({
                  color: theme.colors.pager,
                  textAlign: "center",
                })}
              >
                Gallery
              </h1>
              <hr
                css={(theme) => ({
                  boxShadow: "1px 1px 3px #000",
                  maxWidth: "6.25rem",
                  margin: "1rem auto",
                  borderWidth: ".25rem",
                  borderColor: "inherit",
                  borderRadius: ".1875rem",
                  fontSize: ".875em",
                  opacity: 0.25,
                  color: theme.colors.pager,
                })}
              />
            </Col>
          </Row>
        </Container>
      </header>
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
            {chunkArray(galleryData, 3).map((chunk, i) => (
              <div
                key={"chunk" + i}
                css={{
                  display: "flex",
                  boxAlign: "stretch",
                  alignItems: "stretch",
                  flexShrink: 0,
                  boxOrient: "horizontal",
                  boxDirection: "normal",
                  flexDirection: "row",
                  marginBottom: ".175rem",
                  "@media(min-width: 750px)": {
                    marginBottom: "1.4rem",
                  },
                }}
              >
                {chunk.map((item) => (
                  <Thumbnail
                    key={item.node.id}
                    fluid={item.node.childImageSharp.fluid}
                    alt={item.node.name}
                    onClick={onClick}
                    show={show}
                    onHide={onHide}
                  />
                ))}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const data = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default Gallery;
